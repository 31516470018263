import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    //导航选择变色控制变量
    navColor:'home'
  },
  getters: {
  },
  mutations: {
    navColorclick(state,item){
      state.navColor=item.item
    }
  },
  actions: {
  },
  modules: {
  }
})
