
import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/home'

Vue.use(VueRouter)

const routes = [

  // 登录页路由
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/login')
  },
  
  //注册页路由
  {
    path: '/register',
    name: 'register',
    component: () => import('../views/login/register')
  },
  //重置密码路由
  {
    path: '/resetpwd',
    name: 'resetpwd',
    component: () => import('../views/login/resetpwd')
  },
  //首页路由
  {
    path: '/',
    name: 'home',
    component: Home
  },
  //聊天路由
  // {
  //   path: '/im',
  //   name: 'im',
  //   component: () => import('../views/Im/Ceshi-One.vue'),
  // },
  //个人信息路由
  {
    path: '/per',
    name: 'per',
    component: () => import('../views/per'),
    children: [
      {
        path: 'unverified',
        component: () => import('@/views/per/company_cer/unverified')
      },
      {
        path: 'authenticated',
        component: () => import('@/views/per/company_cer/authenticated/authenticated.vue')
      },
      {
        path: 'amend',
        component: () => import('@/views/per/company_cer/amend/Amend.vue')
      },
      {
        path: 'basic',
        component: () => import('@/views/per/basic')
      },
      {
        path: 'ship',
        component: () => import('@/views/per/ship')
      },
      {
        path: 'shipdetails',
        component: () => import('@/views/per/ship/shipdetails/shipdetails.vue')
      },
    ]
  },
  //发布表单路由
  {
    path: '/fuel',
    name: 'fuel',
    component: () => import('../views/home/fuel')
  },
  //发布页路由
  {
    path: '/posts',
    name: 'posts',
    component: () => import('../views/posts'),
    children:[
      {
        path: '/posts/haveposts',
        component:() => import('../views/posts/haveposts')
      }
    ]
  },
  //发布页路由
  // {
  //   path: '/alterenquiry',
  //   name: 'alterenquiry',
  //   component: () => import('../views/posts/alterenquiry')
  // },
  // 询价确认订单页
  {
    path: '/confirmoffer',
    name: 'confirmoffer',
    component: () => import('../views/posts/confirmoffer')
  },
  // 订单页路由
  {
    path: '/order',
    name: 'order',
    component: () => import('../views/order'),
    children:[
      {
        path:'orderlist',
        component:()=>import('@/views/order/haveorderlist')
      }
    ]

  },
  // 订单详情路由
  {
    path:'/orderdetails',
    name:'orderdetails',
    component: () => import('../views/order/details'),
  },
  // 线下订单上传路由
  {
    path: '/uploadform',
    name: 'uploadform',
    component: () => import('../views/order/uploadnoffline/uploadform')
  },
  //弹窗路由
  {
    path: '/tanchuang',
    name: 'tanchuang',
    component: () => import('../views/commod/tanchuang/tanchuang')
  },

  // //供应商路由
  // {
  //   path:'/supplier',
  //   name:'supplier',
  //   component:() => import('@/views/supplier/suppLier.vue')
  // },
  //   //个人信息路由
  //   {
  //     path:'/permgs',
  //     name:'permgs',
  //     component:() => import('@/views/permgs/Permgs.vue')
  //   },
]


const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})
router.beforeEach((to, from, next) => {
  if (to.path == '/per'){
    next({path:'/per/basic'})
  }else{
    next()
  }
  if (to.path == '/posts'){
    next({path:'/posts/haveposts'})
  }else{
    next()
  }
  if (to.path == '/order'){
    next({path:'/order/orderlist'})
  }else{
    next()
  }
})
// const whiteList = [ "home", "login"];
// router.beforeEach((to,from,next)=>{
// if(sessionStorage.getItem('tokon')){
//   ["login","home",'announcePage','order','supplier'].includes(to.name) ? next({ path: "/" }) : next();
// }else{
//   if (whiteList.includes(to.name)) {
//     // 在免登录白名单，直接进入
//     next();
//   } else {
//     if (sessionStorage.getItem('tokon')) {
//       if (to.name === "login") {
//         next({ path: "/" });
//       } else {
//         next();
//       }
//     } else {
//       if (to.name === "login") {
//         next(); // 否则全部重定向到登录页
//       } else {
//         // 没有登录，这里跳到登录页面
//         next(`/login?redirect=${to.fullPath}`); // 否则全部重定向到登录页
//       }

//     }
//   }
// }

// })
export default router
