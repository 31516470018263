<template>
    <!-- 首页 -->
    <div class="home">

        <!-- 导航栏 -->
        <div>
            <Nav></Nav>
        </div>
        <!-- 内容部分 -->
       <div class="main">
            <div class="ban">
                <!-- 头 -->
                <div class="head">
                    <div class="navmsg">
                            <div class="msg">
                                <div class="msgone">
                                    <span>Smarter Business, Easier Finance</span>
                                </div>

                                <div class="msgtwo">
                                    <span :class="{'cuthover1':cutstate==1}" @click="cutstate=1">
                                        <img src="@/assets/home/oil.png" alt="">
                                        <span class="su1">Fuel service</span>
                                    </span>
                                    <span :class="{'cuthover2':cutstate==2}" @click="cutstate=2">
                                        <img src="@/assets/home/lube.png" alt="">
                                        <span class="su2">Lubricant services</span>
                                    </span>
                                    <span :class="{'cuthover3':cutstate==3}" @click="cutstate=3">
                                        <img src="@/assets/home/spare.png" alt="">
                                        <span class="su3">Spare parts service</span>
                                    </span>
                                    <span :class="{'cuthover4':cutstate==4}" @click="cutstate=4">
                                        <img src="@/assets/home/maintain.png" alt="">
                                        <span class="su4">Maintenance services</span>
                                    </span>
                                </div>

                                <div class="msgthree">
                                    <span>
                                        After you send us your inquiry request, we will match you with the best suppliers and provide you with the best financial service.
                                    </span>
                                </div>

                                <div class="msgfour">

                                    <el-button @click.native="fuelfun()">
                                        <span>REQUEST A QUOTE</span>
                                        <img src="@/assets/home/fafly.png" alt="">
                                    </el-button>

                                </div>

                            </div>
                    </div>
                    <!-- 3D模型背景 -->
                    <div class="earth">
                        <!-- <Earth></Earth> -->
                    </div>
                </div>
                <!-- 轮播图 -->
                <div class="modcard">
                    <el-carousel height="679px">

                        <el-carousel-item>
                           <div class="cardone">
                                <div class="cardtext">
                                    <div class="texthead">
                                        <span>Cutting edgedigital logistics tools</span>
                                    </div>
                                    <div class="text">
                                        <span>
                                            With Sea Rates shippers are able to get access tothe ultimate ecosystem of applications such as ratebenchmarking , cargo tracking , route optimization ,schedules , paperwork and more ! All of this isavailable with Saas access , Apl and white-label webintegrations .
                                        </span>
                                    </div>
                                </div>
                           </div>
                        </el-carousel-item>
<!-- 
                        <el-carousel-item>
                           <div class="cardone">
                            
                           </div>
                        </el-carousel-item>

                        <el-carousel-item>
                           <div class="cardone">
                            
                           </div>
                        </el-carousel-item>

                        <el-carousel-item>
                           <div class="cardone">
                            
                           </div>
                        </el-carousel-item> -->

                    </el-carousel>
                </div>

                <!-- 介绍功能模块内容 -->
                <div class="introduce">
                    <div class="ducehead">
                        <span>Services</span>
                    </div>

                    <div class="picture">

                        <div class="pictureone">
                            <div class="mappict">
                                <img src="@/assets/home/financialservice.png" alt="">
                                <span>Financial Services</span>
                            </div>
                            <div class="textpice">
                                <span>
                                    We can quickly and conveniently provide you with a variety of financial services to support your growth goals and take your business to the next level.
                                </span>
                            </div>
                        </div>

                        <div class="pictureone">
                            <div class="mappict">
                                <img src="@/assets/home/matchingservice.png" alt="">
                                <span>Matching Services</span>
                            </div>
                            <div class="textpice">
                                <span>
                                   We intelligently match you with the most suitable, professional, and creditworthy suppliers, thereby improving your business efficiency while reducing your procurement costs.
                                </span>
                            </div>
                        </div>

                    </div>

                    <div class="picture">

                        <div class="pictureone">
                            <div class="mappict">
                                <img src="@/assets/home/digitalservice.png" alt="">
                                <span>Digital Services</span>
                            </div>
                            <div class="textpice">
                                <span>
                                   Our priority is to offer a mix of digital tools and insights for businesses,no matter the size of your operation, the volume of your sales or the age of your company.
                                </span>
                            </div>
                        </div>
                        
                        <div class="pictureone">
                            <div class="mappict">
                                <img src="@/assets/home/supplierservices.png" alt="">
                                <span>Supplier Services</span>
                            </div>
                            <div class="textpice">
                                <span>
                                   We provide you with more professional suppliers to help you obtain lower procurement costs and higher credit limits.
                                </span>
                            </div>
                        </div>

                    </div>

                </div>

            </div>
       </div>
       <div>
            <Foot></Foot>
       </div>
    </div>
</template>

<script>
    // import Earth from './earth'
    import Nav from '../comm'
    import Foot from '@/views/foot'
    export default{
        name:'HomeNaw',
        data(){
            return{
                follstate:false,
                // 燃油需求订单表控制变量
                fuelstate:false,
                //下划线
                xiastate:'',
                //状态
                cutstate:'1'
            }
        },
        components:{
            Nav,
            Foot,
            // Earth
        },
        methods:{
            follfun(){
                this.follstate=!this.follstate
            },
            // 发布燃油需求单
            fuelfun(){
                var oiltype2=''
                if(sessionStorage.getItem('token')==null){
                    this.$router.push({
                        name:'login',
                    },()=>{})
                    return
                }
                if(this.cutstate==1){
                    oiltype2='fuel_oil'
                }
                if(this.cutstate==2){
                    oiltype2='lubricating_oil'
                }
                if(this.cutstate==3){
                    oiltype2='spare'
                }
                if(this.cutstate==4){
                    oiltype2='service'
                }
                
                this.$router.push({
                    name:'fuel',
                    params:{
                        oiltype:oiltype2
                    }
                },()=>{})

            },
            xiahua(item){
                this.xiastate=item
            }
        }

    }
</script>

<style lang="scss" scoped>
@import './home.scss'
</style>