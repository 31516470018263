<template>
    <!-- 导航栏组件 -->
    <div class="nav">
        <!-- 版心1180 -->
            <div class="ban">
                <!-- logo -->
                <div class="logo">
                    <img src="@/assets/home/logo.png" alt="" @click="cutcolor('home')">
                </div>
                <!-- 导航模块 -->
                <div class="navmod">
                    <span  :class="{'spsu':true,'fontblue':colstate=='Posts'}" @click="cutcolor('Posts')">Requests</span>
                    <span :class="{'spsu':true,'fontblue':colstate=='Order'}" @click="cutcolor('Order')">Orders</span>
                    <span  class="spsu">Vendors</span>
                    <span class="spsu">Help</span>
                    <!-- <span></span> -->

                    <span  class="spsu spimg">
                        <img src="@/assets/home/tongzhi.png" alt=""
                        style="width: 30px; height: 30px; vertical-align: middle">
                        <!-- 未登录状态 -->
                        <span v-show="navmap==null" @click="$router.push({name:'login'})" style="white-space: nowrap;">SIGN IN</span>
                        <!-- 已登录状态 -->
                        <el-dropdown v-show="navmap!=null">
                            <img src="@/assets/home/permap.png" alt=""
                            style="width: 30px; height: 30px; vertical-align: middle">
                            <el-dropdown-menu slot="dropdown">
                                <el-dropdown-item>
                                    <div style="display: flex;align-items: center;border-bottom: 1px solid #D7E1F0 ;padding: 10px;">
                                        <img src="@/assets/home/permap.png" alt="" style="margin-right: 20px;">
                                        <span>Hi, {{ nickname }}</span>
                                    </div>
                                </el-dropdown-item>
                                <el-dropdown-item @click.native="perget">
                                    <span >Account management</span>
                                </el-dropdown-item>
                                <el-dropdown-item @click.native="logoutclick">
                                    <span>Sign out</span>
                                </el-dropdown-item>
                            </el-dropdown-menu>
                        </el-dropdown>

                    </span>
                </div>
            </div>
    </div>
</template>

<script>
import axios from '@/axios'
import URL from '@/url/url'
export default {
  name:'NavNew',
  data () {
    return {

        // 控制导航栏变色
        colstate:'home',
        //个人昵称
        nickname:'Aiden',
        //控制注销按键
        logoutbut:true,
        //控制登录按键
        logbut:true,
        //控制导航栏头像是否显示
        navmap:sessionStorage.getItem('token')
    }
  },
  created(){
    if(sessionStorage.getItem('navColor')==undefined){
        this.colstate='home'
    }else{
        this.colstate=sessionStorage.getItem('navColor')
    }
    if(sessionStorage.getItem('token')!=undefined){
        this.pergetmgs()
        this.logoutbut=true
        this.logbut=false
    }else{
        this.logoutbut=false
        this.logbut=true
    }
  },
  methods:{
    //获取个人信息
    pergetmgs(){
        axios({
            method:'get',
            headers:{'Content-Type':'multipart/form-data'},
            url:URL.userURL.perdet
        }).then(res=>{
            if(res.code==0){
                this.open('Your identity token is incorrect. Please log in again and try again','Error prompt')
                sessionStorage.removeItem('token')
                this.navmap=sessionStorage.getItem('token')
            }
            if(res.code==1){
                this.nickname=res.data.userinfo.firstname+' '+res.data.userinfo.lastname
            }
        })
    },
    //选中功能颜色和跳转页面
    cutcolor(item){
        sessionStorage.setItem('navColor',item)
        if(sessionStorage.getItem('token')==null){
            sessionStorage.removeItem('navColor')
            this.$router.push({
                name:'login'
            },()=>{})
            return
        }
        this.colstate=item
        if(item=='home'){
            this.$router.push({
                name:'home'
            },()=>{})
        }
        if(item=='Posts'){
            this.$router.push({
                name:'posts'
            },()=>{})
        }
        if(item=='Order'){
            this.$router.push({
                name:'order'
            },()=>{})
        }

    },
    //点击进入个人信息页面
    perget(){
        if(sessionStorage.getItem('token')==undefined){
            this.$router.push({
                name:'login'
            },()=>{})
            return
        }
        this.$router.push({
                name:'per',
            },()=>{})
    },
    // 注销
    logoutclick(){

        this.nickname='Aiden'
        axios({
            method:'post',
            headers:{'Content-Type':'application/x-www-form-urlencoded'},
            url:URL.userURL.logout
        }).then(res=>{
            if(res.code==1){

                this.open('Exit successfully','Message prompt')
                sessionStorage.removeItem('token')
                this.navmap=sessionStorage.getItem('token')
                console.log(this.navmap);

            }
        })
    },
    //登录
    logbutclick(){
        this.$router.push({
                name:'login'
            },()=>{})
    },
    //弹窗
    open(item,item2) {
        this.$alert(item, item2, {
          confirmButtonText: 'Confirm',
        });
      },
  },
  watch:{
    navmap(newVal){
        if(newVal==null){
                this.$router.push({
                    name:'home'
                },()=>{})
                sessionStorage.setItem('navColor','home')
        }
    }
  }

}
</script>

<style lang='scss' scoped>
@import './nav.scss'
</style>
