<template>
  <div id="app">
    <router-view/>

  </div>
</template>
<script>
  export default{
    name:'AppApp',
  }
</script>
<style lang="scss">
  *{
    margin: 0px;
    padding: 0px;
  }
  // @font-face {
  //   font-family: 'Segoe UI';
  //   src: url("./font/PingFang\ SC\ Regular.ttf");
  // }
  // html,body{
  //   width: 100%;
  //   height: 100%;
  // }
  // @font-face{
  //   font-family:'MontBold-12';
  //   src: url('./assets/font_format/MontserratAlternates-Bold-12.otf');
  // }
  // @font-face {
  //   font-family:'MontMedium-16';
  //   src: url('./assets/font_format/MontserratAlternates-Medium-16.otf');
  // }
  @font-face {
    font-family:'Montserrat-light';
    src: url('./assets/font_format/Montserrat-Light-6.otf');
  }
  @font-face {
    font-family:'Montserrat-Medium-7';
    src: url('./assets/font_format/Montserrat-Medium-7.otf');
  }
  #app{
    width: 100%;
    height: 100vh;
    // font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-family: 'Montserrat-light', -apple-system, BlinkMacSystemFont;
  }
  ::-webkit-scrollbar {
  display: none;
}

  // @media (max-width:1080px) {
  //         #app{
  //         width: 1080px;
  //         height: 1080px;
  //       }
  // }
//   ::-webkit-scrollbar{
//     display: none;
// }
</style>
