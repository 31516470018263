import {agency} from '@/agency/agency.js'

const url ={
    // 用户接口
    userURL:{
        //登录接口
        login:agency+'/oil/comuser/user/login',
        //图形验证码
        tuke:agency+'/index.php?s=/captcha&t=',
        //个人信息
        perdet:agency+'/oil/comuser/user/index',
        //修改密码
        changepwd:agency+'/oil/comuser/user/changepwd',
        //修改个人信息
        changemgs:agency+'/oil/comuser/user/profile',
        //修改个人头像
        avatarURL:agency+'/oil/comuser/user/change_avatar',
        //注册账号
        register:agency+'/oil/comuser/user/register',
        //发送邮箱验证码
        send:agency+'/oil/comuser/ems/send',
        //重置密码（忘记密码）
        resetpwd:agency+'/oil/comuser/user/resetpwd',
        //变更邮箱
        changeemail:agency+'/oil/comuser/user/changeemail',
        //安全退出
        logout:agency+'/oil/comuser/user/logout',
        //上传文件
        upload:agency+'/oil/comuser/common/upload',
        //公司认证详情
        company:agency+'/oil/comuser/company/companyinfo',
        //申请公司认证
        companyinfo:agency+'/oil/comuser/company/change_companyinfo',
        //修改公司认证信息
        companyedit:agency+'/oil/comuser/company/companyedit'
    },
 
    //公共接口
    commURL:{
        //港口接口
        portURL:agency+'/oil/home/oilport/list',
        //油品接口
        oilURL:agency+'/oil/home/oil/list',
        //国家接口
        countryURL:agency+'/oil/home/country/list',
    },
    //船东接口
    //询价服务
    priceURL:{
        //新增发布
        priceaddURL:agency+'/oil/owner/enquiryorder/add',
        //发布列表
        priceListURL:agency+'/oil/owner/enquiryorder/list',
        //发布详情
        priceinfoURL:agency+'/oil/owner/enquiryorder/info',
        //编辑发布
        editURL:agency+'/oil/owner/enquiryorder/edit',
        //删除发布
        delURL:agency+'/oil/owner/enquiryorder/del',
        //取消发布
        cancelURL:agency+'/oil/owner/enquiryorder/cancel',
    },
    //船舶管理
    shipURL:{
        // 船舶列表
        shiplist:agency+'/oil/owner/shipping/list',
        //添加船舶
        shipadd:agency+'/oil/owner/shipping/add',
        //删除船舶
        shipdel:agency+'/oil/owner/shipping/del',
        //修改船伯
        shippingedit:agency+'/oil/owner/shipping/edit'
    },
    //订单管理
    orderURL:{
        //确定订单
        orderaddURL:agency+'/oil/owner/order/add',
         //订单列表
        orderlistURL:agency+'/oil/owner/order/list',
        //订单详情
        orderinfoURL:agency+'/oil/owner/order/info' 
    },
    //历史订单管理
    historyURL:{
        //新增历史订单
        hisaddURL:agency+'/oil/owner/historyorder/add',
        //历史订单列表
        hislistURL:agency+'/oil/owner/historyorder/list',
        //删除历史订单
        hisdelURL:agency+'/oil/owner/historyorder/del',
        
    },
    //评论管理
    commentURL:{
        //添加评论
        commentaddURL:agency+'/oil/owner/comment/add',
        //子订单评价详情
        commentsonURL:agency+'/oil/owner/comment/info_order',
        

    }


    
}
export default url