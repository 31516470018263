<template>
    <div class="foot">
        <div class="ban">
            <div class="footmsg">
                <div class="fontlang">
                    <div class="lang">
                        <img src="@/assets/foot/qiu.png" alt="">
                        <span>English <i class="el-icon-arrow-down"></i></span>
                    </div>
                    <div class="imgpng">
                        <img src="@/assets/foot/Twitter.png" alt="">
                        <img src="@/assets/foot/Facebook.png" alt="">
                        <img src="@/assets/foot/xin.png" alt="">
                    </div>
                </div>

                <div class="msg">
                    <ul>
                        <li>SERVICES</li>
                        <li>Fuel Service</li>
                        <li>Lubricant services</li>
                        <li>Spare parts service</li>
                        <li>Maintenance Service</li>
                    </ul>

                    <ul>
                        <li>BUSINESS</li>
                        <li>Requests</li>
                        <li>Orders</li>
                        <li>Vendors</li>
                        <li>Account</li>
                    </ul>

                    <ul>
                        <li>COMPANY</li>
                        <li>About</li>
                        <li>Contact us</li>
                        <li>Copyright</li>
                        <li>Help</li>
                    </ul>
                </div>
            </div>
            <div class="bian">
                <a href="https://beian.miit.gov.cn/?spm=5176.27906258.J_9220772140.52.705e3ec6pVD8v8#/Integrated/index">粤ICP备2023036968号-1</a>
            </div>
        </div>
    </div>
</template>

<script>
export default {
  name:'FootSm',
  data () {
    return {
    }
  }
}
</script>

<style lang='scss' scoped>
    @import './foot.scss'
</style>
